<template>
    <div class="download_container">
        <el-row>
          <el-col :span="16" :offset="4">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(3)">技术服务</span> &gt; <span @click="changeNav(3)">技术支持</span> &gt; <span>文档中心</span></p>
          </el-col>
          <el-col :span="16" :offset="4">
            <p class="desc_text">文档中心提供磐石相关产品宣传彩页、安装指导、配置指导、用户手册、命令参考等资料，相关资料最终版本由磐石（南京）云计算有限公司最终提供解释。</p>
          </el-col>
          <el-col :span="16" :offset="4">
            <div class="title">
                <p>检索</p>
            </div>
          </el-col>
          <el-col :span="10" :offset="7">
            <!--    搜索框-->
            <div class="searchBox">
                <input type="text" v-model="searchInput" class="searchInput">
                <div class="searchIcon iconfont icon-sousuo1" @click="search">搜索</div>
            </div>
          </el-col>
          <el-col :span="16" :offset="4">
            <div class="title">
              <p>文档下载</p>
            </div>
          </el-col>
        </el-row>
        <!--    文档列表-->
        <el-row>
          <el-col :span="16" :offset="4">
            <div class="card">
              <p class="head">技术</p>
              <div>
                <div class="listUl">
                  <p v-show="technology.length == 0">暂无相关文档</p>
                  <p @click="showVerify(item.realName,item.id,item.downCount)" v-for="(item,index) in this.technology" :key="index">
                    <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                    <span href="#">{{item.name}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="card">
              <p class="head">宣传</p>
              <div>
                <div class="listUl">
                  <p v-show="propaganda.length == 0">暂无相关文档</p>
                  <p @click="showVerify(item.realName,item.id,item.downCount)" v-for="(item,index) in this.propaganda" :key="index">
                    <img src="@/assets/technicalSupport/domDownload/filedownload.png" />
                    <span href="#">{{item.name}}</span>
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- 验证码对话框 -->
        <el-dialog title="请输入验证码" :visible.sync="verifyVisible" @closed="reactive_verify" :width="`35%`">
          <div class="verify_div">
            <input type="text" v-model="verify_num" placeholder="不区分大小写" class="verify_inp" />
            <Verify ref="Verify" class="verify_show"></Verify>
            <el-button type="primary" :disabled="verify_num.length != 4" @click="active_verify" class="verify_btn">提交</el-button>
            <div class="clearfix"></div>
          </div>
        </el-dialog>
    </div>
</template>

<script>
import {getPsDoc,postDownLoad,postDownLoadDount} from '@/api'
// import axios from 'axios';

export default {
    name: "domDownload",
    data() {
      return {
        // 搜索框
        searchInput: '',
        // 抽屉
        drawer:false,
        // 验证码弹窗展示
        verifyVisible:false,
        // 验证码input数据
        verify_num:'',
        // 文件名称
        fileName:'',
        // 文件id
        fileId:'',
        // 技术分类
        technology:[],
        // 宣传分类
        propaganda:[],
        searchName:'1',
        downCount:0
      }
    },
    methods: {
      // 打开验证码弹窗
      showVerify(fileName,id,count){
        this.verifyVisible = true
        this.fileName = fileName
        this.fileId = id
        this.downCount = count
      },
      // 进行验证
      active_verify(){
        if(this.$refs.Verify.imgCode == this.verify_num.toUpperCase()){
          // this.$downloadLocalFile(this.fileName,this.fileName)
          this.startDown()
          this.verifyVisible = false
          let temp = {
            downloadId:this.fileId,
          }
          let temp2 = {
            id:this.fileId,
            downCount:this.downCount
          }
          console.log(this.fileId)
          postDownLoad(temp)
          postDownLoadDount(temp2)
          
        }else{
          this.$message('验证码输入错误');
        }
      },
      // 下载文档
      /* downLoadFile(fileName) {
        this.$downloadLocalFile(fileName,fileName)
        // this.$downloadLocalFile('NaviAcceptor_v2.0.0_win10.exe', 'NaviAcceptor_v2.0.0_win10.exe')
      }, */
      changeNav(navNum){
        this.$store.dispatch('changeShow',navNum)
      },
      reactive_verify(){
        this.verify_num = ''
        this.$refs.Verify.handleDraw()
      },
      // 获取文档下载列表
      async getPsDoc(){
        let result = await getPsDoc(this.searchInput)
        let technologyList = []
        let propagandaList = []
        // 通过type进行分类
        result.data.filter(item => {
          if(item.type == 1){
            technologyList.push(item)
          }else if(item.type == 2){
            propagandaList.push(item)
          }
        })
        this.technology = technologyList
        this.propaganda = propagandaList
        technologyList = []
        propagandaList = []
      },
      search(){
        this.getPsDoc()
      },
      startDown(){
          // 拼接下载链接并下载
          window.location.href=`${window.ipConfig.downloadUrl}api/down/download?filePath=upload//${this.fileName}&fileName=${this.fileName}`

          // let that = this
          // axios({
          //   method:'post',
          //   url:'/api/down/download',
          //   data:{  
          //     url:'C:\\panshiWeb\\upload\\' + that.fileName
          //   },
          //   responseType:'blob',
          //   transformRequest: [function (data) {
          //     let ret = ''
          //     for (let it in data) {
          //       ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
          //     }
          //     return ret
          //   }],
            
          // })
          // .then(res => {
          //   const content = res.data
          //   const blob = new Blob([content])
          //   if ('download' in document.createElement('a')) {
          //     const elink = document.createElement('a')
          //     elink.download = that.fileName
          //     elink.style.display = 'none'
          //     elink.href = URL.createObjectURL(blob)
          //     document.body.appendChild(elink)
          //     elink.click()
          //     URL.revokeObjectURL(elink.href)
          //     document.body.removeChild(elink)
          //   } else {
          //     navigator.msSaveBlob(blob, that.fileName)
          //   }
          // })
      },
    },
    computed:{
    window: function () {
      // `this` 指向 vm 实例
      return window
    }
  },
    mounted() {
      this.getPsDoc()
    }
}
</script>

<style scoped>
/* 通用样式*/
li {
    list-style: none;
}

a {
    color: #496AB1;
    text-decoration: none;
}
/* 验证码 */
.verify_inp{
  float: left;
  line-height: 38px;
  border: 1px solid #409EFF;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px;
  padding-left: 10px;
}
.verify_show{
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.verify_btn{
  float: left;
}
.clearfix{
  clear: both;
}
/* 导航栏 */
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/*页面样式*/
.download_container{
  padding-bottom: 50px;
}
/* 标题 */
.desc_text{
  font-size: 16px;
  color: #666666;
  margin-top: 30px;
  margin-bottom: 40px;
}
.title{
  margin-top: 30px;
  margin-bottom: 30px;
}
.title p{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;

}
/*搜索框*/
.searchBox {
    width: 100%;
    text-align: right;
    margin: 1% 0 3% 0;
    position: relative;
}

.searchInput {
    padding: 0;
    width: 96%;
    border: 1px solid #cacaca;
    border-radius: 0px;
    line-height: 46px;
    padding-left: 10px;
}

.searchIcon {
  padding: 0;
  cursor: pointer;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  width: 16%;
  text-align: center;
  line-height: 48px;
  color: #fff;
  background: #e40f0f;
}
.searchIcon::before{
  margin-right: 10px;
}
.clearfix{clear: both;}

/*文档列表*/
.card{
  margin-bottom: 30px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border: 1px solid #EBEEF5;
  border-radius: 4px;
  overflow: hidden;
}
.head{
  background: #f2f2f2;
  padding: 10px 20px;
  font-size: 22px;
  font-weight: 500;
}
.listTitle {
  line-height: 48px;
  font-size: 32px;
  color: #101010;
}
.listUl{
  padding: 20px;
}
.listUl p {
  display: inline-block;
  width: 33.33%;
  font-size: 18px;
  line-height: 30px;
  color: #496AB1;
  margin: 0.5% 0;
  cursor: pointer;
}
.listUl p img{
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.listUl p span{
  font-size: 16px;
  line-height: 30px;
}

/* 抽屉文档列表样式 */
.listUld{
  width: 80%;
  margin:0 auto;

}
.listUld p {
  font-size: 18px;
  line-height: 30px;
  color: #496AB1;
  margin: 0.5% 0;
  cursor: pointer;
}
.listUld p img{
  margin-right: 5px;
  position: relative;
  top: 3px;
}
.listUld p a{
  font-size: 14px;
  line-height: 40px;
}
</style>